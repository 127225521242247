<template>
  <div class="main">
    <heads></heads>
    <ctdkbanners></ctdkbanners>
    <div class="ls-title">
      <div class="ls-title-zy"><span>标题：</span>{{ biaot }}</div>
      <div :class="iszhankai == true ? zhaiyaoclasszk : zhaiyaoclass">
        <span>摘要：</span>{{ zhaiy }}
        <div class="ls-title-zhank" @click="iszhankai = !iszhankai">
          {{ iszhankai == true ? "收起" : "展开" }}
        </div>
      </div>
      <div class="ls-title-zy"><span>关键词：</span>{{ guanjianci }}</div>
      <div class="ls-title-zy">
        <span>中图分类推荐：</span
        ><i class="ls-title-zy-i" v-for="(item, key) in ztflhtjlist" :key="key"
          >{{ item.flhname }}（{{ item.flh }}），</i
        >
      </div>
      <div class="ls-title-zy ls-title-zy-50" v-if="txjgval != ''">
        <span>机构：</span>{{ txjgval }}
      </div>
      <div class="ls-title-zy ls-title-zy-50"><span>创新指数：</span>{{ zhiliangfenxi }}</div>
      <div class="ls-title-zy"><span>选择职称：</span>{{ xuanzezhicheng }}</div>
    </div>
    <div class="cons">
      <div class="qikanlicon">
        <div class="qikanlicon-filter">
          <div class="qikanlicon-filter-li">
            <div class="qikanlicon-filter-li-title" @click="isslqk = !isslqk">
              <div class="qikanlicon-filter-li-title-wz">期刊等级</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ isslqk == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="isslqk">
              <el-checkbox-group v-model="checkhxqk" @change="filtercheck">
                <el-checkbox
                  v-for="(item, key) in hexinqikanlist"
                  :key="key"
                  :label="item.key"
                >
                  {{ item.label }}（{{ item.num }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="qikanlicon-filter-li">
            <div class="qikanlicon-filter-li-title" @click="isslwz = !isslwz">
              <div class="qikanlicon-filter-li-title-wz">收录网站</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ isslwz == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="isslwz">
              <el-checkbox-group v-model="checkslwz" @change="filtercheck">
                <el-checkbox
                  v-for="(item, key) in slwzlist"
                  :key="key"
                  :label="item.code"
                >
                  {{ item.sf }}（{{ item.num }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="qikanlicon-filter-li">
            <div class="qikanlicon-filter-li-title" @click="iscbzq = !iscbzq">
              <div class="qikanlicon-filter-li-title-wz">出版周期</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ iscbzq == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="iscbzq">
              <el-checkbox-group v-model="checkcbzq" @change="filtercheck">
                <el-checkbox
                  v-for="(item, key) in cbzqlist"
                  :key="key"
                  :label="item.key"
                >
                  {{ item.label }}（{{ item.num }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="qikanlicon-filter-li">
            <div class="qikanlicon-filter-li-title" @click="isflh = !isflh">
              <div class="qikanlicon-filter-li-title-wz">中图分类</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ isflh == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="isflh">
              <el-checkbox-group v-model="checkflh" @change="filtercheck">
                <el-checkbox
                  v-for="(item, key) in fenleihao"
                  :key="key"
                  :label="item.code"
                >
                  {{ item.name }}（{{ item.num }}/{{ item.sumnum }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="qikanlicon-filter-li">
            <div class="qikanlicon-filter-li-title" @click="isdiyu = !isdiyu">
              <div class="qikanlicon-filter-li-title-wz">地域分布</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ isdiyu == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="isdiyu">
              <el-checkbox-group v-model="checkdiyu" @change="filtercheck">
                <el-checkbox
                  v-for="(item, key) in diyulist"
                  :key="key"
                  :label="item.code"
                >
                  {{ item.sf }}（{{ item.num }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="qikanlicon-filter-li">
            <div
              class="qikanlicon-filter-li-title"
              @click="iskanfaneirong = !iskanfaneirong"
            >
              <div class="qikanlicon-filter-li-title-wz">刊发内容</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ iskanfaneirong == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="iskanfaneirong">
              <el-checkbox-group
                v-model="checkkanfaneirong"
                @change="filtercheck"
              >
                <el-checkbox
                  v-for="(item, key) in kanfaneironglist"
                  :key="key"
                  :label="item.code"
                >
                  {{ item.sf }}（{{ item.num }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <div class="qikanlicon-filter-li">
            <div class="qikanlicon-filter-li-title" @click="isyema = !isyema">
              <div class="qikanlicon-filter-li-title-wz">页码</div>
              <div class="qikanlicon-filter-li-title-icon">
                {{ isyema == true ? "收起" : "展开" }}
              </div>
            </div>
            <div class="qikanlicon-filter-licon" v-show="isyema">
              <el-checkbox-group v-model="checkyema" @change="filtercheck">
                <el-checkbox
                  v-for="(item, key) in yemalist"
                  :key="key"
                  :label="item.code"
                >
                  {{ item.sf }}（{{ item.num }}）
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="tablesfy">
          <div class="tablesfy-paixu tablesfy-paixu1">
            <div class="tablesfy-paixu-title">分类规则：</div>
            <div
              v-for="(item, key) in fenleiarr"
              :key="key"
              :class="item.class"
              @click="changefenlei(item.key, key)"
            >
              <div class="tablesfy-paixu-list-wz">{{ item.label }}</div>
            </div>
          </div>
          <div class="tablesfy-paixu tablesfy-paixu3">
            <!-- <el-button type="warning" @click="clicktgfx">投稿发现</el-button> -->
            <el-button type="success" @click="clickallqk"
              >期刊推荐列表</el-button
            >
          </div>
          <div class="tablesfy-paixu tablesfy-paixu2">
            <div class="tablesfy-paixu-title">排序规则：</div>
            <div
              v-for="(item, key) in paixuarr"
              :key="key"
              :class="item.class"
              @click="changerad(item.key, key)"
            >
              <div class="tablesfy-paixu-list-wz">{{ item.label }}</div>
              <el-icon
                :size="item.iconsize"
                :color="item.iconcolor"
                v-if="item.icon == 'btn'"
              >
                <Bottom />
              </el-icon>
              <el-icon
                :size="item.iconsize"
                :color="item.iconcolor"
                v-if="item.icon == 'top'"
              >
                <Top />
              </el-icon>
            </div>
          </div>
          <div class="table-wsj">
            共找到{{ qkarrfilter.length }}本适合您文章的期刊
          </div>
          <div class="tablesfy-ul">
            <div class="tablesfy-li" v-for="(item, key) in dqqkarr" :key="key">
              <div class="tablesfy-li-img">
                <div class="tablesfy-li-imgs" @click="handleEdit(item.Id)">
                  <el-image
                    :src="proxy.$imgurl + '/qikanfmpic/' + item.qkcode + '.jpg'"
                    style="width: 148px; height: 198px"
                    alt=""
                    fit="fill"
                  />
                </div>
              </div>
              <div class="tablesfy-li-shux">
                <div class="tablesfy-li-shux-ttcon">
                  <div
                    class="tablesfy-li-shux-title"
                    :title="item.qkname"
                    @click="handleEdit(item.Id)"
                  >
                    {{ item.qkname }}
                  </div>
                  <div
                    class="tablesfy-li-shux-btns tablesfy-li-shux-btns-jrdb"
                    @click="adddblit(item)"
                  >
                    加入对比
                  </div>
                  <div
                    class="tablesfy-li-shux-btns tablesfy-li-shux-btns-tgxq"
                    @click="clicktgxq(item)"
                  >
                    投稿详情
                  </div>
                  <div class="tablesfy-li-shux-tuijianzhishu">
                    {{ item.tuijianzhishu }}
                  </div>
                </div>
                <div class="tablesfy-li-shux-kuai">
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 期刊收录：</div>
                    <div class="tablesfy-li-shux-kuais-con">
                      <div
                        class="tablesfy-li-shux-kuais-con-list"
                        v-for="(e, i) in item.slqkdet"
                        :key="i"
                      >
                        <a
                          v-if="e.split('：')[1] != ''"
                          :href="e.split('：')[1]"
                          target="_blank"
                          >{{ e.split("：")[0] }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 目录收录：</div>
                    <div
                      class="tablesfy-li-shux-kuais-con"
                      :title="item.coreperiodical"
                    >
                      {{ item.coreperiodical }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 主管单位：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.zgorg">
                      {{ item.zgorg }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 主办单位：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.zborg">
                      {{ item.zborg }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 国内刊号：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.gnkh">
                      {{ item.gnkh }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 国际刊号：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.gjkh">
                      {{ item.gjkh }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 影响因子：</div>
                    <div class="tablesfy-li-shux-kuais-con">
                      {{ item.yxyz }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 基金占比：</div>
                    <div class="tablesfy-li-shux-kuais-con">
                      {{ item.jjbv }}%
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 出版语种：</div>
                    <div
                      class="tablesfy-li-shux-kuais-con"
                      :title="item.yuzhong"
                    >
                      {{ item.yuzhong }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 出版周期：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.cbzq">
                      {{ item.cbzq }}
                    </div>
                  </div>
                  <div
                    class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50"
                    v-if="item.kz2 && item.kz2 != ''"
                  >
                    <div class="tablesfy-li-shux-kuais-title">♦ 官方网址：</div>
                    <div class="tablesfy-li-shux-kuais-con">
                      <a :href="item.kz2" target="_blank">{{ item.kz2 }}</a>
                    </div>
                  </div>
                  <div
                    class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50"
                    v-if="item.kz3 && item.kz3 != ''"
                  >
                    <div class="tablesfy-li-shux-kuais-title">♦ 投稿网址：</div>
                    <div class="tablesfy-li-shux-kuais-con">
                      <a :href="item.kz3" target="_blank">{{ item.kz3 }}</a>
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 联系电话：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.tel">
                      {{ item.tel }}
                    </div>
                  </div>
                  <div class="tablesfy-li-shux-kuais tablesfy-li-shux-kuais-50">
                    <div class="tablesfy-li-shux-kuais-title">♦ 电子邮箱：</div>
                    <div class="tablesfy-li-shux-kuais-con" :title="item.dzyx">
                      {{ item.dzyx }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tablesfy-fy" v-if="qkarrfilter.length > 0">
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :total="totals"
              :current-page="currentPage"
              @current-change="fanye"
            /> -->
            <el-pagination
              background
              :current-page="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totals"
              @size-change="handleSizeChange"
              @current-change="fanye"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 侧边对比 -->
    <div class="dblist">
      <div class="dblist-listmain">
        <el-badge :value="dblistarr.length" class="dblist-item" type="warning">
          <div class="dblist-lock" @click="isdblist = !isdblist">
            <el-icon :size="20">
              <ScaleToOriginal />
            </el-icon>
          </div>
        </el-badge>
        <div class="dblist-ul" v-show="isdblist">
          <div class="dblist-list-wtj" v-if="dblistarr.length == 0">
            未添加对比
          </div>
          <div class="dblist-list" v-for="(item, key) in dblistarr" :key="key">
            <div class="dblist-list-name">{{ item.qkname }}</div>
            <div class="dblist-list-set">
              <el-button
                type="danger"
                plain
                size="small"
                @click="removedblist(key)"
                >移除</el-button
              >
            </div>
          </div>
          <div class="dblist-ksdb">
            <el-button type="primary" size="small" @click="kashiduibi()"
              >开始对比</el-button
            >
            <el-button type="warning" size="small" @click="deletedblist"
              >清空</el-button
            >
            <el-button size="small" @click="isdblist = !isdblist"
              >关闭</el-button
            >
          </div>
        </div>
      </div>

      <div class="dblist-listmain">
        <div
          class="dblist-lock dblist-weixin"
          @click="isweixinkefu = !isweixinkefu"
        >
          <el-icon :size="20">
            <Service />
          </el-icon>
        </div>
        <div class="dblist-weixinul dblist-weixinul2" v-if="isweixinkefu">
          <el-image
            src="/img/common/kefu2.png"
            style="width: 100px; height: 100px"
            alt=""
            fit="fill"
          />
        </div>
      </div>
    </div>

    <!-- 底部对比 -->
    <div class="dibudblist" v-show="isdbdblist">
      <div class="dibudblist-text">
        已加入<span>{{ dblistarr.length }}</span
        >本期刊在对比列表中
      </div>
      <div class="dibudblist-btn">
        <el-button type="success" size="small" @click="kashiduibi"
          >开始对比</el-button
        >
        <el-button size="small" @click="isdblist = true">查看列表</el-button>
        <el-button size="small" @click="isdbdblist = false">关闭显示</el-button>
      </div>
    </div>

    <!-- 对话框 -->
    <el-dialog
      width="900px"
      top="12vh"
      v-model="dialogTableVisible"
      title="首站-论文投稿智能助手"
    >
      <div class="tc-con">
        <!-- <div class="tc-con-title">
          <div class="tc-con-title-main">
            查同导刊（论文发表智能投稿平台），将智能自动、语义识别、观点匹配、创新查询和智能推荐等特点融入一体，为每位用户提供论文发表期刊关联推荐建和分析报告。
          </div>
        </div> -->
        <div class="tc-con-table">
          <el-table :data="dblistarr" border style="width: 100%">
            <el-table-column prop="qkname" label="期刊名称" width="240">
              <template #default="scope">
                <div
                  class="tc-con-table-title"
                  @click="handleEdit(scope.row.Id)"
                >
                  {{ scope.row.qkname }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="cbzq" label="出版周期" width="90" />
            <el-table-column prop="slqkdet" label="期刊等级" width="130">
              <template #default="scope">
                <div class="slqkdet-con">
                  <div
                    class="slqkdet-list"
                    v-for="(item, key) in scope.row.slqkdet"
                    :key="key"
                  >
                    <div v-if="item.split('：')[1] != ''">
                      {{ item.split("：")[0] }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="yxyz" label="影响因子" width="90" />
            <!-- <el-table-column prop="tgcgl" label="发表价值" width="90" /> -->
            <el-table-column prop="qkggtype" label="见刊周期">
              <template #default="scope">
                <div class="tc-con-table-jkzq">
                  {{ scope.row.qkggtype == 1 ? "-" : "较快" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="coreperiodical" label="期刊目录" />
          </el-table>
        </div>
        <div class="tc-con-xtitle">
          <div class="tc-con-xtitle-main">
            此报告由系统自动生成，不代表公司观点，仅供作者参考，平台方不承担任何责任。
          </div>
        </div>
        <div class="tc-con-btn">
          <el-button type="primary" @click="shengchengbaogao">
            <el-icon class="el-icon--left"><Memo /></el-icon>生成报告
          </el-button>
          <div class="tc-con-btn-smwz">
            注：需要允许浏览器打开新窗口，否则无法生成报告。
          </div>
        </div>
        <div class="tc-con-kefu">
          <div class="tc-con-kefu-tips">官方客服</div>
          <el-image
            style="width: 90px; height: 90px"
            src="/img/common/kefu2.png"
            :fit="'fill'"
            alt="客服咨询"
          ></el-image>
        </div>
      </div>
    </el-dialog>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import { get, post } from "@/utils/index";
import { useRouter } from "vue-router";
import { constant } from "lodash";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 标题摘要
let biaot = ref("");
let zhaiy = ref("");
let guanjianci = ref("");
// 原始数据列表
let qksource = reactive([]);
// 填写的机构值
let txjgval = ref("");
// 相关期刊列表
let qkarr = reactive([]);
// 筛选后的期刊列表
let qkarrfilter = reactive([]);
// 当前页的数据
let dqqkarr = reactive([]);
// 中图分类号推荐
let ztflhtjlist = reactive([]);
// 质量分析
let zhiliangfenxi = ref(0);
// 期刊id
let qikanid = ref("");
//提交的职称id
let zhichengid = ref("");
// 选择的职称
let xuanzezhicheng = ref('')
// 展开收缩
let iszhankai = ref(false);
let zhaiyaoclass = ref("ls-title-zy ls-title-zynr");
let zhaiyaoclasszk = ref("ls-title-zy ls-title-zynr-zk");
// 核心期刊
let isslqk = ref(true);
let checkhxqk = ref([]);
const checkhxqkyuan = [
  {
    label: "CSTPCD",
    key: "CSTPCD",
  },
  {
    label: "北大核心",
    key: "北大核心",
  },
  {
    label: "CSSCI",
    key: "CSSCI",
  },
  {
    label: "SCI",
    key: "SCI",
  },
  {
    label: "EI",
    key: "EI",
  },
  {
    label: "CSCD",
    key: "CSCD",
  },
  {
    label: "普刊",
    key: "普刊",
  },
];
let hexinqikanlist = reactive([]);
// 出版周期
let iscbzq = ref(true);
let checkcbzq = ref([]);
const cbzqyuan = [
  {
    label: "年刊",
    key: "年刊",
  },
  {
    label: "半年刊",
    key: "半年刊",
  },
  {
    label: "旬刊",
    key: "旬刊",
  },
  {
    label: "季刊",
    key: "季刊",
  },
  {
    label: "双月刊",
    key: "双月刊",
  },
  {
    label: "月刊",
    key: "月刊",
  },
  {
    label: "半月刊",
    key: "半月刊",
  },
  {
    label: "双周刊",
    key: "双周刊",
  },
  {
    label: "周刊",
    key: "周刊",
  },
  {
    label: "周二刊",
    key: "周二刊",
  },
];
let cbzqlist = reactive([]);
// 地域
let isdiyu = ref(true);
let checkdiyu = ref([]);
const diyulistyuan = [
  { sf: "北京", code: "11" },
  { sf: "北京2", code: "10" },
  { sf: "天津", code: "12" },
  { sf: "河北", code: "13" },
  { sf: "山西", code: "14" },
  { sf: "内蒙古", code: "15" },
  { sf: "辽宁", code: "21" },
  { sf: "吉林", code: "22" },
  { sf: "黑龙江", code: "23" },
  { sf: "上海", code: "31" },
  { sf: "江苏", code: "32" },
  { sf: "浙江", code: "33" },
  { sf: "安徽", code: "34" },
  { sf: "福建", code: "35" },
  { sf: "江西", code: "36" },
  { sf: "山东", code: "37" },
  { sf: "河南", code: "41" },
  { sf: "湖北", code: "42" },
  { sf: "湖南", code: "43" },
  { sf: "广东", code: "44" },
  { sf: "广西", code: "45" },
  { sf: "海南", code: "46" },
  { sf: "重庆", code: "50" },
  { sf: "四川", code: "51" },
  { sf: "贵州", code: "52" },
  { sf: "云南", code: "53" },
  { sf: "西藏", code: "54" },
  { sf: "陕西", code: "61" },
  { sf: "甘肃", code: "62" },
  { sf: "青海", code: "63" },
  { sf: "宁夏", code: "64" },
  { sf: "新疆", code: "65" },
  { sf: "台湾", code: "71" },
  { sf: "香港", code: "81" },
  { sf: "澳门", code: "82" },
  { sf: "其他", code: "00" },
];
let diyulist = reactive([]);
// 刊发内容
let iskanfaneirong = ref(true);
let checkkanfaneirong = ref([]);
const kanfaneirongyuan = [
  {
    sf: "其他",
    code: "0",
  },
  {
    sf: "学术论文",
    code: "1",
  },
  {
    sf: "学术+科普",
    code: "2",
  },
  {
    sf: "科普文章",
    code: "3",
  },
];
let kanfaneironglist = reactive([]);

// 页码
let isyema = ref(true);
let checkyema = ref([]);
const yemayuan = [
  {
    sf: "1-100页",
    code: "1",
  },
  {
    sf: "101-200页",
    code: "2",
  },
  {
    sf: "201页以上",
    code: "3",
  },
];
let yemalist = reactive([]);

// 收录网站
let isslwz = ref(true);
let checkslwz = ref([]);
const slwzyuan = [
  {
    sf: "知网",
    code: "知网",
  },
  {
    sf: "维普",
    code: "维普",
  },
  {
    sf: "万方",
    code: "万方",
  },
];
let slwzlist = reactive([]);

// 分类号
let isflh = ref(true);
let checkflh = ref([]);
const fenleihaoyuan = [
  {
    code: "A",
    name: "马克思主义、列宁主义、毛泽东思想、邓小平理论",
    sumnum: 7,
  },
  { code: "B", name: "哲学、宗教", sumnum: 22 },
  { code: "C", name: "社会科学总论", sumnum: 498 },
  { code: "D", name: "政治、法律", sumnum: 287 },
  { code: "E", name: "军事", sumnum: 7 },
  { code: "F", name: "经济", sumnum: 352 },
  { code: "G", name: "文化、科学、教育、体育", sumnum: 25 },
  { code: "H", name: "语言、文字", sumnum: 50 },
  { code: "I", name: "文学", sumnum: 75 },
  { code: "J", name: "艺术", sumnum: 90 },
  { code: "K", name: "历史、地理", sumnum: 69 },
  { code: "N", name: "自然科学总论", sumnum: 353 },
  { code: "O", name: "数理科学和化学", sumnum: 7 },
  { code: "P", name: "天文学、地球科学", sumnum: 212 },
  { code: "Q", name: "生物科学", sumnum: 70 },
  { code: "R", name: "医药、卫生", sumnum: 1043 },
  { code: "S", name: "农业科学", sumnum: 433 },
  { code: "T", name: "工业技术", sumnum: 86 },
  { code: "U", name: "交通运输", sumnum: 150 },
  { code: "V", name: "航空、航天", sumnum: 60 },
  { code: "X", name: "环境科学、安全科学", sumnum: 70 },
  { code: "Z", name: "综合性图书", sumnum: 196 },
  { code: "U2", name: "铁路运输", sumnum: 2 },
  { code: "TB", name: "一般工业技术", sumnum: 70 },
  { code: "TD", name: "矿业工程", sumnum: 59 },
  { code: "TE", name: "石油、天然气工业", sumnum: 75 },
  { code: "TF", name: "冶金工业", sumnum: 85 },
  { code: "TG", name: "金属学与金属工艺", sumnum: 56 },
  { code: "TH", name: "机械、仪表工业", sumnum: 113 },
  { code: "TJ", name: "武器工业", sumnum: 24 },
  { code: "TK", name: "能源与动力工程", sumnum: 72 },
  { code: "TL", name: "原子能技术", sumnum: 17 },
  { code: "TM", name: "电工技术", sumnum: 89 },
  { code: "TN", name: "电子技术、通信技术", sumnum: 150 },
  { code: "TP", name: "自动化技术、计算机技术", sumnum: 105 },
  { code: "TQ", name: "化学工业", sumnum: 157 },
  { code: "TS", name: "轻工业、手工业、生活服务业", sumnum: 147 },
  { code: "TU", name: "建筑科学", sumnum: 146 },
  { code: "TV", name: "水利工程", sumnum: 71 },
  { code: "S1", name: "农业基础科学", sumnum: 1 },
  { code: "S2", name: "农业工程", sumnum: 1 },
  { code: "S6", name: "园艺", sumnum: 1 },
  { code: "R1", name: "预防医学、卫生学", sumnum: 1 },
  { code: "R2", name: "中国医学", sumnum: 3 },
  { code: "R4", name: "临床医学", sumnum: 1 },
  { code: "R6", name: "外科学⑨", sumnum: 1 },
  { code: "R8", name: "特种医学⑨", sumnum: 1 },
  { code: "R9", name: "药学", sumnum: 1 },
  { code: "P7", name: "海洋学", sumnum: 1 },
  { code: "O1", name: "数学", sumnum: 44 },
  { code: "O3", name: "力学", sumnum: 23 },
  { code: "O4", name: "物理学", sumnum: 49 },
  { code: "O6", name: "化学", sumnum: 35 },
  { code: "O7", name: "晶体学", sumnum: 1 },
  { code: "N1", name: "自然科学概况、现状、进展", sumnum: 1 },
  { code: "N5", name: "自然科学丛书、文集、连续性出版物", sumnum: 1 },
  { code: "K2", name: "中国史", sumnum: 1 },
  { code: "K9", name: "地理", sumnum: 1 },
  { code: "I0", name: "文学理论", sumnum: 1 },
  { code: "I2", name: "中国文学", sumnum: 1 },
  { code: "G0", name: "文化理论", sumnum: 20 },
  { code: "G2", name: "信息与知识传播", sumnum: 108 },
  { code: "G3", name: "科学、科学研究", sumnum: 51 },
  { code: "G4", name: "教育", sumnum: 391 },
  { code: "G8", name: "体育", sumnum: 40 },
  { code: "F2", name: "经济管理", sumnum: 1 },
  { code: "F7", name: "贸易经济", sumnum: 2 },
  { code: "D0", name: "政治学、政治理论", sumnum: 1 },
  { code: "C8", name: "统计学", sumnum: 2 },
  { code: "Other", name: "其他", sumnum: 385 },
];
let fenleihao = reactive([]);

// 排序list
let paixuarr = reactive([
  {
    label: "机构优先",
    key: "fhjsvaluejigou-",
    class: "tablesfy-paixu-list",
    iconsize: "14px",
    iconcolor: "#333",
    icon: "",
  },
  {
    label: "分类优先",
    key: "xtcynumdouble-",
    class: "tablesfy-paixu-list",
    iconsize: "14px",
    iconcolor: "#333",
    icon: "",
  },
  {
    label: "影响因子",
    key: "yxyz-",
    class: "tablesfy-paixu-list",
    iconsize: "14px",
    iconcolor: "#333",
    icon: "btn",
  },
  {
    label: "影响因子",
    key: "yxyz+",
    class: "tablesfy-paixu-list",
    iconsize: "14px",
    iconcolor: "#333",
    icon: "top",
  },
  {
    label: "投稿指数",
    key: "tuijianzhishu-",
    class: "tablesfy-paixu-list",
    iconsize: "14px",
    iconcolor: "#333",
    icon: "btn",
  },
  {
    label: "投稿指数",
    key: "tuijianzhishu+",
    class: "tablesfy-paixu-list",
    iconsize: "14px",
    iconcolor: "#333",
    icon: "top",
  },
]);

// 分类list
let fenleiarr = reactive([
  {
    label: "内容优先",
    key: "1",
    class: "tablesfy-paixufenlei-list tablesfy-paixufenlei-list-active",
  },
  {
    label: "时间优先",
    key: "2",
    class: "tablesfy-paixufenlei-list",
  },
  {
    label: "职称优先",
    key: "3",
    class: "tablesfy-paixufenlei-list",
  },
]);

// 分页先关
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(10);

// 对比清单
let isdblist = ref(false);
let isdbdblist = ref(false);
let dblistarr = reactive([]);
let isweixinkefu = ref(false);

// 对话框
let dialogTableVisible = ref(false);

// 跳转投稿发现
const clicktgfx = () => {
  router.push({
    path: "/xkzs/tgfx",
    query: {
      id: router.currentRoute.value.query.id,
    },
  });
};

// 查看全部期刊
const clickallqk = () => {
  router.push({
    path: "/xkzs/all",
    query: {
      id: router.currentRoute.value.query.id,
      cszs: zhiliangfenxi.value,
    },
  });
};

// 分页
const fanye = (val) => {
  // console.log(val)
  page.value = val;
  xianshishuju(page.value);
};
//
const handleSizeChange = (val) => {
  // console.log(val)
  pageSize.value = val;
  xianshishuju(page.value);
};
// 根据页码显示数据
const xianshishuju = (pg) => {
  dqqkarr.length = 0;
  //显示的页码标签
  currentPage.value = pg;
  qkarrfilter.forEach((e, i) => {
    if (i >= pg * pageSize.value - pageSize.value && i < pg * pageSize.value) {
      dqqkarr.push(e);
    }
  });
};
// 添加到对比清单
const adddblit = (data) => {
  if (dblistarr.length > 9) {
    ElMessage({
      message: "最多添加10项",
      type: "warning",
    });
    return false;
  }
  let istj = true;
  if (dblistarr.length > 0) {
    dblistarr.forEach((e) => {
      // 重复添加
      if (e.Id == data.Id) {
        istj = false;
        ElMessage({
          message: "不能重复添加",
          type: "warning",
        });
      }
    });
  }
  if (istj) {
    dblistarr.push(data);
    ElMessage({
      message: "添加成功",
      type: "success",
    });
    // 每次添加成功后显示底部列表
    isdbdblist.value = true;
  }
};
// 从对比清单移除
const removedblist = (i) => {
  // console.log(i);
  dblistarr.splice(i, 1);
  ElMessage({
    message: "已删除",
    type: "success",
  });
};
// 清空对比清单
const deletedblist = () => {
  dblistarr.length = 0;
  ElMessage({
    message: "已清空",
    type: "success",
  });
};
// 开始对比
const kashiduibi = () => {
  // 至少两项
  if (dblistarr.length < 2) {
    ElMessage({
      message: "至少选择两项才能进行对比",
      type: "warning",
    });
    return false;
  }
  isdblist.value = false;
  dialogTableVisible.value = true;

  // console.log(dblistarr)
};
// 筛选
function filtercheck() {
  // 每一组数据从源数据中进行筛选  qkarrfilter筛选完成的数据  qkarr源数据
  qkarrfilter.length = 0;
  let resultlist1 = qkarr; //收录情况
  let resultlist2 = qkarr; //地域分布
  let resultlist3 = qkarr; //期刊分类号
  let resultlist4 = qkarr; //出版周期
  let resultlist5 = qkarr; //刊发内容
  let resultlist6 = qkarr; //页码
  let resultlist7 = qkarr; //收录网站

  // 筛选核心期刊
  if (checkhxqk.value.length > 0) {
    resultlist1 = resultlist1.filter((item) =>
      checkhxqk.value.find((bItem) => item.coreperiodical.includes(bItem))
    );
  }
  // 筛选地域
  if (checkdiyu.value.length > 0) {
    resultlist2 = resultlist2.filter((item) =>
      checkdiyu.value.find((bItem) => item.qkdystr == bItem)
    );
  }
  // 筛选分类号
  if (checkflh.value.length > 0) {
    resultlist3 = resultlist3.filter((item) =>
      checkflh.value.find((bItem) => item.qkflstr == bItem)
    );
  }
  // 筛选出版周期
  if (checkcbzq.value.length > 0) {
    resultlist4 = resultlist4.filter((item) =>
      checkcbzq.value.find((bItem) => item.cbzq == bItem)
    );
  }
  // 筛选刊发内容
  if (checkkanfaneirong.value.length > 0) {
    resultlist5 = resultlist5.filter((item) =>
      checkkanfaneirong.value.find((bItem) => item.kz7 == bItem)
    );
  }

  // 筛选页码
  if (checkyema.value.length > 0) {
    resultlist6 = resultlist6.filter((item) =>
      checkyema.value.find((bItem) => item.maxpage == bItem)
    );
  }

  // 筛选收录网站
  if (checkslwz.value.length > 0) {
    // console.log(resultlist7)
    // console.log(checkslwz)
    resultlist7 = resultlist7.filter((item) =>
      checkslwz.value.find((bItem) =>
        item.slqkdetnew.includes((bItem + "：h").replace(" ", ""))
      )
    );
  }

  // 筛选出的多组结果取交集
  let resultlisttemp = resultlist1.filter((item) =>
    resultlist2.find((bItem) => item.Id == bItem.Id)
  );
  let resultlisttemp2 = resultlisttemp.filter((item) =>
    resultlist3.find((bItem) => item.Id == bItem.Id)
  );
  let resultlisttemp3 = resultlisttemp2.filter((item) =>
    resultlist4.find((bItem) => item.Id == bItem.Id)
  );
  let resultlisttemp4 = resultlisttemp3.filter((item) =>
    resultlist5.find((bItem) => item.Id == bItem.Id)
  );
  let resultlisttemp5 = resultlisttemp4.filter((item) =>
    resultlist6.find((bItem) => item.Id == bItem.Id)
  );
  qkarrfilter = resultlisttemp5.filter((item) =>
    resultlist7.find((bItem) => item.Id == bItem.Id)
  );
  // 赋值总条数，加载第一页
  totals.value = qkarrfilter.length;
  xianshishuju(1);
  // 重新生成左侧列表
  fhdyorflh();
}
// 选择排序方式
const changerad = (val, index) => {
  // 未填写机构时不予进行机构优先排序
  if (index == 0 && txjgval.value == "") {
    ElMessage({
      type: "warning",
      message: "未填写机构或未找到相关期刊，无法选择机构优先",
    });
    return;
  }

  // 修改样式
  paixuarr.forEach((e, i) => {
    if (i == index) {
      e.class = "tablesfy-paixu-list tablesfy-paixu-list-active";
      e.iconcolor = "#fff";
    } else {
      e.class = "tablesfy-paixu-list";
      e.iconcolor = "#333";
    }
  });
  // 排序
  dqqkarr.length = 0;
  switch (val) {
    case "xtcynumdouble-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "xtcynumdouble"));
      break;
    case "fhjsvaluejigou-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "fhjsvaluejigou"));
      break;
    case "yxtj-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "yxtj"));
      break;
    case "tuijianzhishu-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "tuijianzhishu"));
      break;
    case "tuijianzhishu+":
      dqqkarr.push(...sortByKey(qkarrfilter, "tuijianzhishu"));
      break;
    case "yxyz-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "yxyz"));
      break;
    case "yxyz+":
      dqqkarr.push(...sortByKey(qkarrfilter, "yxyz"));
      break;
    case "jjbv-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "jjbv"));
      break;
    case "xtcynum+":
      dqqkarr.push(...sortByKey(qkarrfilter, "xtcynum"));
      break;
    case "xtcynum-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "xtcynum"));
      break;
    case "sumidf+":
      dqqkarr.push(...sortByKey(qkarrfilter, "sumidf"));
      break;
    case "sumidf-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "sumidf"));
      break;
    case "sumqktfidf+":
      dqqkarr.push(...sortByKey(qkarrfilter, "sumqktfidf"));
      break;
    case "sumqktfidf-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "sumqktfidf"));
      break;
    case "jjbv+":
      dqqkarr.push(...sortByKey(qkarrfilter, "jjbv"));
      break;
    case "jjbv-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "jjbv"));
      break;
    case "fhjsvalue+":
      dqqkarr.push(...sortByKey(qkarrfilter, "fhjsvalue"));
      break;
    case "fhjsvalue-":
      dqqkarr.push(...sortDownByKey(qkarrfilter, "fhjsvalue"));
      break;
    default:
      dqqkarr.push(...sortByKey(qkarrfilter, "yxyz"));
  }
  // console.log(dqqkarr)
  // dqqkarr.forEach(e=>{
  //   console.log(e.yxtj)
  // })

  // 加载第一页
  xianshishuju(1);
};
// 选择分类方式
const changefenlei = (val, index) => {
  // 修改样式
  fenleiarr.forEach((e, i) => {
    if (i == index) {
      e.class = "tablesfy-paixufenlei-list tablesfy-paixufenlei-list-active";
    } else {
      e.class = "tablesfy-paixufenlei-list";
    }
  });
  // 初始化数据
  qkarr.length = 0;
  qkarrfilter.length = 0;
  // 内容优先
  if (index == 0) {
    qkarr.push(...qksource);
    // 期刊收录拆分
    qkarrfilter.push(...qkarr);
    // 生成期刊分类号和地域待选项
    fhdyorflh();
    // 获取治疗分析
    getzlfxzs();
    // 总条数
    totals.value = qkarrfilter.length;
    // 默认第一页
    xianshishuju(1);
    // 默认分类优先
    changerad("xtcynumdouble-", 1);

    // 推荐优先
  } else if (index == 1) {
    qksource.forEach((e) => {
      if (e.qkggtype > 1) {
        qkarr.push(e);
      }
    });
    // 期刊收录拆分
    qkarrfilter.push(...qkarr);
    // 生成期刊分类号和地域待选项
    fhdyorflh();
    // 总条数
    totals.value = qkarrfilter.length;
    // 默认第一页
    xianshishuju(1);
    // 使用双排序
    sortDowntjyx();
    //职称优先
  } else if (index == 2) {
    if (zhichengid.value == "") {
      ElMessage({
        type: "warning",
        message: "未选择职称，无法选择职称优先",
      });
      return;
    }
    get("/journalTitle/GetZcqkDet", {
      id: zhichengid.value,
      num: 1000,
    }).then((res) => {
      // console.log(res);
      if (res.data.length == 0) {
        ElMessage({
          type: "warning",
          message: "未找到相关期刊",
        });
        return;
      }
      // 从原始数组中找出与返回数组id相同的数组，用作展示
      // console.log(qksource);
      res.data.forEach((e) => {
        let zddqk = qksource.filter((ele) => {
          return ele.Id == e;
        });
        if (zddqk.length > 0) {
          qkarr.push(zddqk[0]);
        }
      });
      // 期刊收录拆分
      qkarrfilter.push(...qkarr);
      // 生成期刊分类号和地域待选项
      fhdyorflh();
      // 获取治疗分析
      getzlfxzs();
      // 总条数
      totals.value = qkarrfilter.length;
      // 默认第一页
      xianshishuju(1);
      // 默认分类优先
      changerad("xtcynumdouble-", 1);
    });
  }
};
// 生成质量分析指数 普刊数量/总数  取对数
const getzlfxzs = () => {
  // 普刊数
  let pukannum = qkarrfilter.filter((e) => {
    return e.coreperiodical == "普刊";
  }).length;
  if(pukannum==0){
    pukannum=1
  }
  // 全部刊数量
  let allkannum = 0;
  hexinqikanlist.forEach((e) => {
    allkannum += e.num;
  });
  // console.log(pukannum)
  // console.log(allkannum)
  zhiliangfenxi.value = Math.abs(Math.log(pukannum / allkannum)).toFixed(2);

  // 生成投稿指数
  qkarr.forEach((e) => {
    if (parseFloat(e.kz12) == 0) {
      //  e.tuijianzhishu=0.01
      // e.tuijianzhishu=parseFloat((zhiliangfenxi.value/0.01).toFixed(2))
      e.tuijianzhishu = Math.log(
        parseFloat((zhiliangfenxi.value / 0.01).toFixed(2)) + 1.01
      ).toFixed(2);
    } else {
      // e.tuijianzhishu=parseFloat((zhiliangfenxi.value/parseFloat(e.kz12)).toFixed(2))
      e.tuijianzhishu = Math.log(
        parseFloat((zhiliangfenxi.value / parseFloat(e.kz12)).toFixed(2)) + 1.01
      ).toFixed(2);
    }
  });
  // console.log(qkarr)
};

// 生成报告
const shengchengbaogao = () => {
  // console.log(dblistarr)
  let idss = "";
  dblistarr.forEach((e, i) => {
    if (i < dblistarr.length - 1) {
      idss += e.Id + ",";
    } else {
      idss += e.Id;
    }
  });
  get("/Journal/SubContrast", {
    ids: idss,
  }).then((res) => {
    const url = router.resolve({
      path: "/xkzs/report",
      query: {
        id: res.data,
      },
    });
    window.open(url.href);
  });
};
testLog();
// 获取数据
function testLog() {
  get("/journalRecommend/GetBynid", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // 基本属性
    qikanid.value = res.data.Id;
    biaot.value = res.data.btword;
    zhaiy.value = res.data.zyword;
    guanjianci.value = res.data.kwword;
    // 保存选择的职称id
    zhichengid.value = res.data.sbzcjb;
    // 保存选择的职称
    if(res.data.sbzczy!=''){
      let xuanzezhichengarr= JSON.parse(res.data.sbzczy)
      xuanzezhichengarr.forEach((e,i)=>{
        if(i<xuanzezhichengarr.length-1){
          xuanzezhicheng.value+=e+'-'
        }else{
          xuanzezhicheng.value+=e
        } 
      })
    }

    // 中途分类号
    ztflhtjlist.push(...JSON.parse(res.data.ztflhstr));
    // 是否在提交的时候填写了机构istxjg
    if (res.data.writerorg && res.data.writerorg != "") {
      txjgval.value = res.data.writerorg;
      // 如果存在机构，调用曾的接口加权重，加默认排序字段上fhjsvalue
      post("/journalOrganKeywords/getJournalByKeywords", {
        organ: txjgval.value,
        limit: 200,
      }).then((result) => {
        // console.log(result);
        if (result.result.length == 0) {
          txjgval.value = "";
          // 格式化数据  保存原始数据到本地
          JSON.parse(res.data.xgqk).qklist.forEach((e) => {
            e.slqkdet = e.slqkdet.split(";");
            qksource.push(e);
          });
          // 默认内容优先  加载全部
          changefenlei("", 0);
        } else {
          // console.log(result.result);
          JSON.parse(res.data.xgqk).qklist.forEach((e) => {
            e.slqkdetnew = e.slqkdet; //保存一个收录维普万方知网网址
            e.slqkdet = e.slqkdet.split(";"); //格式化收录维普万方知网网址
            // 找到相同的  e.fhjsvalue+ele.tf/2
            try {
              result.result.forEach((ele) => {
                if (e.Id == ele.journalId) {
                  // console.log(
                  //   `${e.fhjsvalue}-------------${ele.totalTermFreq}-----------${e.qkname}`
                  // );
                  e.fhjsvaluejigou = (e.fhjsvalue + ele.totalTermFreq * 3) / 2;
                  throw Error();
                } else {
                  e.fhjsvaluejigou = e.fhjsvalue;
                }
              });
            } catch (error) {}
            qksource.push(e);
          });
          // qksource.forEach(e=>{
          //   console.log(`${e.fhjsvaluejigou}-----${e.qkname}`)
          // })

          // 默认内容优先  加载全部
          changefenlei("", 0);
        }
      });
    } else {
      txjgval.value = "";
      // 格式化数据  保存原始数据到本地
      JSON.parse(res.data.xgqk).qklist.forEach((e) => {
        e.slqkdetnew = e.slqkdet; //保存一个收录维普万方知网网址
        e.slqkdet = e.slqkdet.split(";"); //格式化收录维普万方知网网址
        qksource.push(e);
      });
      // 默认内容优先  加载全部
      changefenlei("", 0);
    }
  });
}
// 根据返回的数据生成地域和分类号列表
const fhdyorflh = () => {
  // console.log(qkarrfilter)
  // 初始化数据
  hexinqikanlist.length = 0;
  cbzqlist.length = 0;
  diyulist.length = 0;
  fenleihao.length = 0;
  kanfaneironglist.length = 0;
  yemalist.length = 0;
  slwzlist.length = 0;
  // 核心期刊
  checkhxqkyuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      let coreperiodicaltemp = "," + ele.coreperiodical + ",";
      if (coreperiodicaltemp.includes("," + e.key + ",")) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      hexinqikanlist.push(Object.assign(e, { num: hjsum }));
      sortDownByKey(hexinqikanlist, "num");
    }
  });
  // 出版周期
  cbzqyuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      if (e.key == ele.cbzq) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      cbzqlist.push(Object.assign(e, { num: hjsum }));
      sortDownByKey(cbzqlist, "num");
    }
  });
  // 刊发内容
  kanfaneirongyuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      if (e.code == ele.kz7) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      kanfaneironglist.push(Object.assign(e, { num: hjsum }));
      sortDownByKey(kanfaneironglist, "num");
    }
  });
  // 页码
  yemayuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      if (e.code == ele.maxpage) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      yemalist.push(Object.assign(e, { num: hjsum }));
      sortDownByKey(yemalist, "num");
    }
  });

  // 收录网站
  slwzyuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      if (ele.slqkdetnew.includes((e.code + "：h").replace(" ", ""))) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      slwzlist.push(Object.assign(e, { num: hjsum }));
      sortDownByKey(slwzlist, "num");
    }
  });

  // 地域
  diyulistyuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      if (e.code == ele.qkdystr) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      diyulist.push(Object.assign(e, { num: hjsum }));
      sortDownByKey(diyulist, "num");
    }
  });
  // 分类号
  fenleihaoyuan.forEach((e, i) => {
    let hjsum = 0;
    let ishj = false;
    // 计数
    qkarrfilter.forEach((ele, key) => {
      if (e.code == ele.qkflstr) {
        ishj = true;
        hjsum++;
      }
    });
    // 存在则添加到待选项
    if (ishj) {
      let numsorttemp = (hjsum / e.sumnum).toFixed(4);
      fenleihao.push(Object.assign(e, { num: hjsum, numsort: numsorttemp }));
      sortDownByKey(fenleihao, "numsort");
    }
  });
};
//数组对象方法排序:升序
const sortByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};
//数组对象方法排序:降序
const sortDownByKey = (array, key) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x > y ? -1 : x < y ? 1 : 0;
  });
};
// 推荐优先双排序，降序 优先
const sortDowntjyx = () => {
  // 修改样式
  paixuarr.forEach((e, i) => {
    e.class = "tablesfy-paixu-list";
    e.iconcolor = "#333";
  });
  dqqkarr.length = 0;
  dqqkarr.push(
    ...qkarrfilter.sort((a, b) => {
      if (b.qkggtype !== a.qkggtype) {
        return b.qkggtype - a.qkggtype;
      } else {
        return new Date(b.yxtj) - new Date(a.yxtj);
      }
    })
  );
};
// 点击投稿详情
const clicktgxq = (e) => {
  const url = router.resolve({
    path: "/xkzs/tgqk",
    query: {
      id: e.Id,
      tjid: router.currentRoute.value.query.id,
    },
  });
  window.open(url.href);
};

// 跳转详情
const handleEdit = (id) => {
  // console.log(dblistarr)
  // const url = router.resolve({
  //   path: "/xkzs/del",
  //   query: {
  //     id: id,
  //   },
  // });
  // window.open(url.href);
  window.open('//journal.yuanpingjia.com.cn/Journal/Details/'+id)
};
</script>

<style scoped>
:deep(.el-radio.is-bordered) {
  margin-bottom: 10px;
}
:deep(.el-table .cell) {
  display: flex;
  align-items: center;
}
:deep(.tablesfy-li-db .el-button) {
  width: 100%;
}
:deep(.el-checkbox) {
  display: flex;
  align-items: center;
  height: 24px;
  margin-right: 0;
}
:deep(.el-checkbox) {
  height: auto;
  margin: 8px 0;
  white-space: normal;
}
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 5px 0 50px 0;
  font-size: 14px;
}
.ls-title {
  width: 1200px;
  margin: auto;
  padding: 20px 0 0 0;
}
.ls-title::after{
  content: '';
  display: table;
  clear:both;
}

.ls-title-zy {
  width: 100%;
  position: relative;
  padding: 10px 15px;
  background: #f9f9f9;
  box-sizing: border-box;
  text-align: justify;
  line-height: 24px;
  margin-bottom: 5px;
  font-size: 14px;
  float: left;
}
.ls-title-zy-50{
  width: 50%;
}
.ls-title-zynr {
  height: 63px;
  padding: 10px 60px 10px 15px;
  overflow: hidden;
}
.ls-title-zynr-zk {
  height: auto;
  padding: 10px 60px 10px 15px;
  overflow: hidden;
}
.ls-title-bt {
  font-size: 28px;
  margin-bottom: 15px;
}
.ls-title-zy > span {
  font-weight: bold;
}
.ls-title-zy-i {
  font-style: normal;
}
.ls-title-zhank {
  position: absolute;
  right: 0;
  top: 5px;
  width: 60px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
}
.table-wsj {
  width: 100%;
  float: left;
  color: #999;
}
.cons-set-list {
  font-weight: bold;
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  padding: 0 0 10px 0;
}
.cons-set-list:after {
  content: "";
  display: table;
  clear: both;
}
.set-shaoxuan {
  width: auto;
  height: 32px;
  line-height: 32px;
  float: left;
}
.set-ele {
  float: left;
  width: calc(100% - 70px);
}
.cons-select {
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;
}
.cons-select-title {
  font-weight: bold;
}
.kuaikuai {
  width: 10px;
  height: 4px;
  background: #409eff;
  margin: 0 1px;
}
.kuaikuaihui {
  width: 10px;
  height: 4px;
  background: #eee;
  margin: 0 1px;
}
.baiotouclass {
  display: flex;
  align-items: center;
}
.icons {
  width: 18px;
  margin: 0 0 0 5px;
  height: 18px;
  border-radius: 50%;
  background: #909399;
  color: #fff;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  font-weight: bold;
}
.jdt {
  width: 60%;
  height: 100%;
}
.tablesfy {
  width: 930px;
}
.tablesfy:after {
  content: "";
  display: table;
  clear: both;
}
.tablesfy-li {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}
.tablesfy-li:hover {
  background: #fafafa;
}
.tablesfy-li:after {
  content: "";
  display: table;
  clear: both;
}
.tablesfy-li-img {
  width: 150px;
  height: auto;
  float: left;
}
.tablesfy-li-db {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tablesfy-li-shux {
  width: 780px;
  height: 200px;
  float: left;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
}
.tablesfy-li-leid {
  float: left;
  width: 210px;
  height: 200px;
}
.tablesfy-li-shux-kuai {
  width: 100%;
  height: auto;
}
.tablesfy-li-shux-kuai:after {
  content: "";
  display: table;
  clear: both;
}
.tablesfy-li-shux-kuais {
  float: left;
  min-height: 20px;
  padding: 1px 0;
}
.tablesfy-li-shux-kuais:after {
  content: "";
  display: table;
  clear: both;
}
.tablesfy-li-shux-kuais-title {
  width: 85px;
  min-height: 20px;
  line-height: 20px;
  float: left;
  color: #797979;
  font-weight: bold;
}
.tablesfy-li-shux-kuais-con {
  width: calc(100% - 85px);
  min-height: 20px;
  line-height: 20px;
  float: left;
  box-sizing: border-box;
  padding: 0 10px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tablesfy-li-shux-kuais-con-list {
  display: inline;
}
.tablesfy-li-shux-kuais-con-list a {
  margin-right: 10px;
}
.tablesfy-li-shux-kuais-con-list a:visited,
.tablesfy-li-shux-kuais-con-list a:link {
  text-decoration: none;
}
.tablesfy-li-shux-kuais-con-list a:hover {
  text-decoration: underline;
}
.tablesfy-li-shux-kuais-50 {
  width: 50%;
}
.tablesfy-li-shux-ttcon {
  width: 100%;
  height: 33px;
  border-bottom: 1px dashed #ddd;
  margin-bottom: 8px;
}
.tablesfy-li-shux-title {
  width: auto;
  font-size: 20px;
  float: left;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 8px;
}
.tablesfy-li-shux-title:hover {
  color: #409eff;
}
.tablesfy-li-shux-btns {
  float: left;
  width: 80px;
  height: 26px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  border-radius: 2px;
}
.tablesfy-li-shux-btns-jrdb {
  background: #409eff;
}
.tablesfy-li-shux-btns-tgxq {
  background: #67c23a;
}
.tablesfy-li-shux-btns:hover {
  opacity: 0.9;
}
.tablesfy-li-shux-js {
  color: #797979;
  line-height: 24px;
  border-bottom: 1px dashed #ccc;
  padding: 10px 0 20px 0;
  text-align: justify;
  margin: 0 0 10px 0;
}
.tablesfy-fy {
  margin-top: 10px;
  float: left;
}

.dblist {
  width: 40px;
  height: auto;
  position: fixed;
  left: calc(50% + 610px);
  top: 500px;
  font-size: 14px;
}
.dblist-listmain {
  position: relative;
  float: right;
  width: 40px;
  height: 45px;
}
.dblist-ul {
  position: absolute;
  bottom: -40px;
  right: 50px;
  width: 340px;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px #eee;
}
.dblist-ul:after {
  content: "";
  display: table;
  clear: both;
}
.dblist-list {
  float: left;
  width: 100%;
  height: auto;
  padding: 5px 0;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 5px 0;
}
.dblist-list:after {
  content: "";
  display: flex;
  clear: both;
}
.dblist-list-name {
  float: left;
  width: 260px;
  height: auto;
  line-height: 24px;
}
.dblist-list-set {
  float: right;
  width: 60px;
  height: auto;
  text-align: right;
}
.dblist-ksdb {
  float: left;
  width: 100%;
  text-align: center;
  padding: 15px 0 10px 0;
}
.dblist-lock {
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 0 10px #eee;
  text-align: center;
  line-height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dblist-lock:hover {
  background: #409eff;
  color: #fff;
}
.dblist-list-wtj {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #999;
}

.tc-con {
  position: relative;
  width: 860px;
  height: 100%;
}
.tablesfy-li-imgs {
  width: 150px;
  height: 200px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: pointer;
}
.qikanlicon {
  display: flex;
  justify-content: space-between;
}
.qikanlicon-filter {
  width: 250px;
}
.qikanlicon-filter-li {
  border: 1px solid #eee;
  box-shadow: 0 0 3px #eee;
  margin-bottom: 10px;
}
.qikanlicon-filter-li-title {
  width: 100%;
  height: 38px;
  background: #e3ecfd;
  font-weight: bold;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.qikanlicon-filter-li-title-wz {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
}
.qikanlicon-filter-li-title-icon {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #888;
  font-size: 12px;
  cursor: pointer;
}
.qikanlicon-filter-licon {
  padding: 10px 15px;
  max-height: 400px;
  overflow-y: scroll;
}

.tablesfy-paixu {
  width: 50%;
  height: 35px;
  float: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.tablesfy-ul {
  float: left;
}
.tablesfy-paixu1 {
  width: 80%;
}
.tablesfy-paixu2 {
  width: 100%;
}
.tablesfy-paixu3 {
  width: 20%;
  justify-content: flex-end;
}
.tablesfy-paixu-list {
  width: 80px;
  height: 26px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  background: #fafafa;
  margin-right: 15px;
  cursor: pointer;
}
.tablesfy-paixu-list:hover {
  background: #f2f2f2;
}
.tablesfy-paixu-list-active {
  border: 1px solid #409eff;
  background: #409eff;
  color: #fff;
}
.tablesfy-paixu-list-active:hover {
  background: #409eff;
}
.tablesfy-paixufenlei-list {
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  background: #fff9f9;
  margin-right: 15px;
  cursor: pointer;
}
.tablesfy-paixufenlei-list:hover {
  background: #fef0f0;
}
.tablesfy-paixufenlei-list-active {
  border: 1px solid #f56c6c;
  background: #f56c6c;
  color: #fff;
}
.tablesfy-paixufenlei-list-active:hover {
  background: #f56c6c;
}
.dblist-item {
  position: absolute;
  right: 0;
  top: 0;
}
.tc-con-title {
  margin-bottom: 10px;
}
.tc-con-title-main {
  text-align: justify;
}
.tc-con-xtitle {
  margin-top: 10px;
}
.tc-con-xtitle-main {
  text-align: justify;
  color: #999;
}
.tc-con-btn {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.tc-con-btn-smwz {
  margin-left: 20px;
  color: #f56c6c;
}
.dblist-weixin {
  position: absolute;
  right: 0;
  top: 0;
}
.dblist-weixinul {
  position: absolute;
  bottom: -40px;
  right: 50px;
  width: 100px;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px #eee;
}
.dblist-weixinul2 {
  width: 120px;
  height: 120px;
}
.dblist-weixinul:after {
  content: "";
  display: table;
  clear: both;
}
.slqkdet-con::after {
  content: "";
  display: table;
  clear: both;
}
.slqkdet-list {
  float: left;
  margin-right: 5px;
}
.tablesfy-paixu-title {
  padding-right: 5px;
  font-weight: bold;
}

.dibudblist {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 50px;
  border-top: 1px solid #ddd;
  box-shadow: 0 0 20px #ddd;
  background: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dibudblist-text {
  margin-right: 20px;
}
.dibudblist-text span {
  font-weight: bold;
  color: #f00;
  padding: 0 5px;
  font-size: 20px;
}
.tc-con-table-title {
  cursor: pointer;
  color: #409eff;
}
.tc-con-table-title:hover {
  text-decoration: underline;
}
.tc-con-table-jkzq {
  color: #f00;
}
.tablesfy-li-shux-tuijianzhishu {
  float: right;
  height: 34px;
  line-height: 34px;
  color: #409eff;
  font-weight: bold;
  font-size: 18px;
}
.tc-con-kefu {
  position: absolute;
  right: 0;
  bottom: -11px;
  width: 90px;
  height: 90px;
}
.tc-con-kefu-tips {
  position: absolute;
  left: 20px;
  top: 88px;
  z-index: 10;
  width: 50px;
  height: 15px;
  background: #fff;
  text-align: center;
  font-size: 12px;
  color: #000;
}
</style>
